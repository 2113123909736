export const Projects = [
	{
		id: 1,
		title: 'Writzer',
		description: 'Full Stack MERN- CMS website',
		imgUrl:
			'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665332004/My%20Websites%20assets/portfolio-projects/storyio_bbq3ft.jpg',
		deployedUrl: 'https://writzer.netlify.app/',
		clientSource: 'https://github.com/vinodkotagiri/WRITZER-client',
		serverSource: 'https://github.com/vinodkotagiri/WRITZER-server',
	},
	{
		id: 2,
		title: 'BizBook',
		description: 'Full Stack MERN- E-commerce book store app',
		imgUrl:
			'https://res.cloudinary.com/vinodkotagiri/image/upload/v1673329766/My%20Websites%20assets/portfolio-projects/bizbook_jl1pyb.jpg',
		deployedUrl: 'https://bizbook-io.netlify.app/',
		clientSource: 'https://github.com/vinodkotagiri/bizbook-client',
		serverSource: 'https://github.com/vinodkotagiri/bizbook-server',
	},
	{
		id: 3,
		title: 'AWS Polymer Clone',
		description: 'Full Stack MERN- AWS Polymer Clone App Clone',
		imgUrl:
			'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665334654/My%20Websites%20assets/portfolio-projects/membook_sztp5t.jpg',
		deployedUrl: 'https://aws-polymer-clone.netlify.app/',
		clientSource: 'https://github.com/vinodkotagiri/aws-polymer-search-clone-frontend',
		serverSource: 'https://github.com/vinodkotagiri/aws-polymer-search-clone-backend',
	},
	{
		id: 4,
		title: 'Chatter Beak',
		description: 'Full Stack MERN-  A realtime messaging  app ',
		imgUrl:
			'https://res.cloudinary.com/vinodkotagiri/image/upload/v1672331407/My%20Websites%20assets/portfolio-projects/twitter-clone_brze70.jpg',
		deployedUrl: 'https://chatterbeak.netlify.app/',
		clientSource: 'https://github.com/vinodkotagiri/chatterBeak-frontend',
		serverSource: 'https://github.com/vinodkotagiri/chatterBeak-backend',
	},
	// {
	// 	id: 5,
	// 	title: 'Student Allocation',
	// 	description: 'Full Stack MERN- Student Teacher Allocation System',
	// 	imgUrl:
	// 		'https://res.cloudinary.com/vinodkotagiri/image/upload/v1672330899/My%20Websites%20assets/portfolio-projects/student-mentor_tnbxgj.jpg',
	// 	deployedUrl: 'https://stme-allocation.netlify.app/',
	// 	clientSource: 'https://github.com/vinodkotagiri/student-teacher-booking-appointment-client',
	// 	serverSource: 'https://github.com/vinodkotagiri/student-teacher-booking-appointment-server',
	// },
	{
		id: 5,
		title: 'Project Management App',
		description: 'Full Stack MERN- Project Management App using GraphQL Server',
		imgUrl:
			'https://res.cloudinary.com/vinodkotagiri/image/upload/v1671432595/My%20Websites%20assets/portfolio-projects/960x0_t9eb1y.jpg',
		deployedUrl: 'https://project-mgmt-gql.web.app/',
		clientSource: 'https://github.com/vinodkotagiri/projectmgmt-gql-client',
		serverSource: 'https://github.com/vinodkotagiri/proj-mgmt-gql-server',
	},
]
